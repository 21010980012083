<script setup lang="ts"></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.09467 2.09467C3.42914 0.760201 5.42603 0.25 8 0.25H14C16.574 0.25 18.5709 0.760201 19.9053 2.09467C21.2398 3.42914 21.75 5.42603 21.75 8V14C21.75 15.9506 21.4585 17.5641 20.7401 18.8128C20.0039 20.0923 18.8645 20.9113 17.3443 21.3416C17.1288 21.4026 16.8974 21.3642 16.7133 21.2368C16.5291 21.1094 16.4115 20.9063 16.3927 20.6832C16.2156 18.5909 13.9928 16.72 11 16.72C8.00719 16.72 5.78438 18.5909 5.60733 20.6832C5.58845 20.9063 5.47087 21.1094 5.28673 21.2368C5.10259 21.3642 4.87116 21.4026 4.65571 21.3416C3.13552 20.9113 1.99613 20.0923 1.25993 18.8128C0.541478 17.5641 0.25 15.9506 0.25 14V8C0.25 5.42603 0.760201 3.42914 2.09467 2.09467ZM3.15533 3.15533C2.2398 4.07086 1.75 5.57397 1.75 8V14C1.75 15.8294 2.02852 17.1409 2.56007 18.0647C2.95322 18.748 3.50844 19.2603 4.28506 19.6155C5.02949 17.0088 7.85826 15.22 11 15.22C14.1417 15.22 16.9705 17.0088 17.7149 19.6155C18.4916 19.2603 19.0468 18.748 19.4399 18.0647C19.9715 17.1409 20.25 15.8294 20.25 14V8C20.25 5.57397 19.7602 4.07086 18.8447 3.15533C17.9291 2.2398 16.426 1.75 14 1.75H8C5.57397 1.75 4.07086 2.2398 3.15533 3.15533ZM11 6.75C9.43422 6.75 8.17 8.01423 8.17 9.58002C8.17 11.1485 9.43693 12.42 11 12.42C12.5631 12.42 13.83 11.1485 13.83 9.58002C13.83 8.01423 12.5658 6.75 11 6.75ZM6.67 9.58002C6.67 7.18581 8.60578 5.25 11 5.25C13.3942 5.25 15.33 7.18581 15.33 9.58002C15.33 11.9715 13.3969 13.92 11 13.92C8.60307 13.92 6.67 11.9715 6.67 9.58002Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped></style>
